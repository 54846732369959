import Box from '@spraoi/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import Avatar from '../../components/Avatar';
import ContactCTA from '../../components/ContactCTA';
import Icon from '../../components/Icon';
import LinkList from '../../components/LinkList';
import Map from '../../components/Map';
import SEO from '../../components/SEO';
import Section from '../../components/Section';
import landMap from '../../data/maps/land.json';
import usePeople from '../../effects/use-people';

const AboutUsPage = ({ location: { pathname } }) => {
  const people = usePeople();

  return (
    <>
      <SEO
        description="Zemfyre designs and manufactures secure industrial communications solutions."
        pathname={pathname}
        title="About Us"
      />

      <Section sx={{ pt: 0, px: [0, null, 7] }}>  
        <Box
          sx={{
            alignItems: 'center',
            bg: 'white',
            borderRadius: [null, null, 2],
            boxShadow: 3,
            display: [null, null, null, 'flex'],
            justifyContent: 'space-between',
            mt: ['1px', null, 7],
            p: [6, null, null, 7],
          }}
        >
          <Box>
            <h3>About Zemfyre</h3>
            <Box as="p" sx={{ color: 'text.subtle', lineHeight: 2, mt: 4 }}>
            Zemfyre is a leading provider of innovative patent-pending Industrial Ethernet Solutions for enabling secure IIoT 
            Cloud connectivity and acceleration of Industry 4.0 adoption. Zemfyre uses the latest game-changing Single Pair Ethernet 
            (SPE) standard to provide seamless secure Ethernet connectivity and power to Field level IIoT devices (sensors and actuators).

            </Box>
          </Box>
        </Box>
      </Section>
      <ContactCTA />
    </>
  );
};

AboutUsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutUsPage;
